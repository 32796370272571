import { InvocationResult } from '../interfaces/invocation-result';
import { PaginatedData } from '../interfaces/paginated-data';

export class InvocationResultUtil {

  constructor() {
  }

  static mapToPaginatedData<T>(result: InvocationResult | null): PaginatedData<T> | null {
    if (result) {
      const pagination = result.pagination
      let data = undefined
      const resultData = result.data
      if (resultData !== null) {
        data = typeof resultData === 'undefined' ? [] : (Array.isArray(resultData) ? resultData : [resultData])
        return {
          data: data,
          page: pagination ? pagination.page : 0,
          perPage: pagination ? pagination.perPage : (data ? data.length : 0),
          count: pagination ? pagination.count : (data ? data.length : 0)
        }
      }
      return null
    }
    return null
  }
}
